






























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import { EventBus } from '@/config/config';

@Component({
  components: {
    'thumbnail-list': () => import('@/components/common/ThumbnailList.vue'),
  }
})
export default class TicketOption extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public place!: any;
  public loading: boolean = false;
  public renderCount: number = 0;

  public mounted() {
    EventBus.$on('thumbnailListReset', () => {
      this.loading = false;
      setTimeout(() => {
        this.loading = true;
        setTimeout(() => {
          this.$forceUpdate();
          this.renderCount += 1;
        }, 100);
      }, 700);
    });
    this.loading = true;
  }

  public open(e) {
    const re = e.currentTarget.parentNode;
    if (re.classList.contains('on')) {
      re.classList.remove('on');
    } else {
      re.classList.add('on');
    }
  }
}
